/* ME Style Variables */
@import "../colors";

$primary: #6998ce;
$secondary: #02263d;
$light: #e6e6e6;
$fade-blue: rgba(2,38,61,.7);
$danger: darken(#FF7D7D, 33%);

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #495058;
$body-color:                        #222;
$header-bg:                         darken($primary, 12%) !default;
$navbar-bg:                         darken($primary, 12%) !default;
$footer-bg:                         darken($primary, 12%) !default;
$link-color:                        darken(#0d94e2, 20%) !default;
$dropdown-bg:                       $secondary;
$dropdown-hover-bg:                 none;
$dropdown-divider-bg:               #777;

$dropdown-link-color:               #eee;
$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            $primary;

$mobile-navbar-bg:                  #495058;
$static-page-header-color:          $primary;
$static-page-subtitle-color:        $secondary;


//Process Page Styling
$process-page-themes: (
  "claim": $primary,
  "holder": $primary
) !default;

$claim-header-bg:                   $secondary;
$holder-header-bg:                  #59bc3b;

$btn-pop-bg:                        $secondary;
$tooltip-icon-color:                $secondary;

/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/banner.jpg');
$body-background-image-url:         url('/images/bg_texture.png');

$claim-header-logo-url:             url('/images/process_logo.png');
$claim-header-logo-width:           204px;
$claim-header-logo-height:          75px;

$holder-header-logo-url:             url('/images/process_logo.png');
$holder-header-logo-width:           204px;
$holder-header-logo-height:          75px;

$nav-tabs-border-color:             darken($primary, 12%) !default;
$nav-tabs-hover-bg-color:           darken($primary, 12%) !default;

$home-logo-url:                     url('/images/me_logo.png');
$home-logo-width:                   421px;
$home-logo-height:                  105px;
$home-logo-resize-value-map:        (width: 105px,
                                     height: $home-logo-height,
                                     background-image: $home-logo-url);

$jumbotron-bg:                      $fade-blue !important;
$footer-logo-breakdown-size:        0 !important;

$footer-logo-url:                   url('/images/maine_footer_logo.png');
$footer-logo-width:                 200px;
$footer-logo-height:                57px;


$treasurer-logo-url:                url('/images/me_logo.png');
$treasurer-logo-width:              341px;
$treasurer-logo-height:             85px;
$treasurer-logo-resize-value-map:   (width: 95px,
                                     height: $treasurer-logo-height,
                                     background-image: $treasurer-logo-url);

$claimform-image-url:               url('/images/ME_sample_claim_form.png');

$icon-email-url:                    none;
$icon-facebook-url:                 url('/images/icon_facebook.png');
$icon-instagram-url:                url('/images/icon_instagram.png');
$icon-twitter-url:                  url('/images/icon_twitter.png');
$icon-youtube-url:                  url('/images/icon_youtube.png');
$icon-linkedin-url:                 none;

$kelmar-logo-force-dark:            true;

$social-icon-width:                 50px;
$social-icon-height:                50px;

$nav-tabs-link-active-bg:           darken($primary, 12%) !default;

$yiq-contrasted-threshold: 144;